.primary-button {
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    text-align: center;
    width: 190px;
    height: 49px;
    background-color: #1500ff;
    transition-duration: 0.5s;
    border-radius: 10px;
}

.primary-button:hover {
    background-color: transparent;
    border-color: #1500ff;
    color: #000000;
    border-width: 2px;
}